var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","text-xs-center":"","fluid":"","mb-16":""}},[_vm._t("default",null,null,{ filtered: _vm.listWithFilter, items: _vm.items ? _vm.items : _vm.itemsTable }),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md6":""}},[(!_vm.hideNew)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("Criar Novo"))+" ")],1):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('Filtrar...'),"clearable":""},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.listWithFilter,"hide-default-footer":_vm.hideDefaultFooter,"items-per-page":_vm.itemsPerPage,"sort-by.sync":"name","show-expand":_vm.showExpand,"expanded":_vm.expanded,"item-key":"id"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":!_vm.inlineToggle},on:{"input":function($event){return _vm.toggle(item)}},model:{value:(item.enable),callback:function ($$v) {_vm.$set(item, "enable", $$v)},expression:"item.enable"}})]}},{key:"item.extra-column",fn:function(ref){
var item = ref.item;
return [_vm._t("extra-column",null,null,item)]}},{key:"item.more-extra-column",fn:function(ref){
var item = ref.item;
return [_vm._t("more-extra-column",null,null,item)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("more-btn",null,null,item),(!_vm.hideEdit)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","dark":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("edit")])],1):_vm._e(),(!_vm.hideDelete)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","dark":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("delete")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_vm._t("expanded",null,null,{ headers: headers, item: item })]}}],null,true)},[_c('v-progress-linear',{attrs:{"progress":"","color":"blue","indeterminate":""}})],1),_vm._t("footer"),(_vm.formclass)?_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeFormByUser.apply(null, arguments)}},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeFormByUser}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c(_vm.componentForm,{tag:"component",attrs:{"itemToEdit":_vm.itemToEdit,"lock":_vm.lockForm},on:{"save":_vm.saveForm}})],1)],1)],1):_vm._e(),(!_vm.hideDelete)?_c('ConfirmDelete',{attrs:{"endpoint":_vm.endpoint,"ID":_vm.deleteItemID,"show":_vm.showDeleteModal,"msg":_vm.deleteMessage,"msgItem":_vm.deleteMessageItem},on:{"close":function($event){_vm.showDeleteModal = false},"onConfirm":function($event){return _vm.deletedConfirm()}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }