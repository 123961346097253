<template>
    <v-dialog v-model="show" @keydown.esc="onCancel()" @keydown.enter="onConfirm()" persistent max-width="800">
        <v-card>
            <v-card-title class="headline primary white--text">{{ $t("Deseja realmente excluir?") }}</v-card-title>

            <v-card-text>
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title> {{ msg }} </v-list-item-title>
                        <v-list-item-title class="text-h5 mb-1"> {{ msgItem }} </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="onConfirm">
                    <v-icon dark left>check_circle</v-icon>
                    {{ $t("Sim") }}
                </v-btn>
                <v-btn color="error" @click="onCancel">
                    <v-icon dark left>cancel</v-icon>
                    {{ $t("Não") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "Confirm",
    props: {
        ID: {
            type: [Number, String],
        },
        endpoint: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
        msg: {
            type: String,
        },
        msgItem: {
            type: String,
        },
    },
    data() {
        return {};
    },
    watch: {
        dialog() {
            // this.onCancel();
        },
    },
    methods: {
        onConfirm() {
            this.$http
                .delete(this.endpoint, { id: this.ID })
                .then((result) => {
                    this.$eventHub.$emit("msgSuccess", result.message ? result.message : this.$t("Dado excluído com sucesso."));
                    this.$emit("onConfirm");
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao excluir dado."));
                });
        },
        onCancel() {
            this.$emit("close");
        },
    },
};
</script>